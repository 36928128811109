export default {
    install(Vue) {
        Vue.mixin({
            data() {
                return {
                    $config: Vue.prototype.$config
                }
            },
            methods: {
                getConfig() {
                    return this.$http.get('config').then((response) => {
                        for(let config of response.data) {
                            this.$config[config.name] = config.value;
                        }
                    })
                },
            }
        });
    }
}
