<template>
    <button @click="!loading ? clicked() : null">
        <template v-if="loading">
            <i class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>
        </template>
        <slot v-else></slot>
    </button>
</template>

<script>
    export default {
        props: ['loading'],
        methods: {
            clicked() {
                this.$emit('click');
            }
        }
    }
</script>
