import Vue from 'vue'
import Router from 'vue-router'
import { token } from './../services/Auth.js'

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer');

// Views
const ExactWebhook = () => import('@/views/exact/Webhook');
const TeamleaderWebhook = () => import('@/views/teamleader/Webhook');
const TopdeskIndex = () => import('@/views/topdesk/Index');
const SettingsIndex = () => import('@/views/settings/Index');
const InvoicingIndex = () => import('@/views/invoicing/Index');
const Login = () => import('@/views/auth/Login');
const DebtorsIndex = () => import('@/views/debtors/Index');

Vue.use(Router);

export default new Router({
    mode: 'history', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'open active',
    scrollBehavior: () => ({y: 0}),
    routes: [
        {
            path: '/',
            redirect: '/dashboard',
            name: 'Home',
            component: DefaultContainer,
            beforeEnter: (to, from, next) => {
                if(!token()) {
                    return next({name: 'login'});
                }
                return next();
            },
            children: [
                {
                    path: 'dashboard',
                    name: 'dashboard',
                    component: TopdeskIndex
                },
                {
                    path: '/settings',
                    name: 'settings.index',
                    component: SettingsIndex
                },
                {
                    path: '/invoicing',
                    name: 'invoicing.index',
                    component: InvoicingIndex
                },
                {
                    path : '/debtors',
                    name : 'debtors.index',
                    component : DebtorsIndex
                }
            ]
        },
        {
            path: '/exact/webhook',
            name: 'exact.webhook',
            component: ExactWebhook
        },
        {
            path: '/teamleader/webhook',
            name: 'teamleader.webhook',
            component: TeamleaderWebhook
        },
        {
            path: '/',
            name: '',
            component: {
                render(c) {
                    return c('router-view')
                }
            },
            children: [
                {
                    path: 'login',
                    name: 'login',
                    component: Login
                }
            ]
        },
    ]
})
