import Toastr from 'vue-toastr';
require('vue-toastr/src/vue-toastr.scss');

export default {
    install(Vue) {
        // Add zero expiration to error toastr's.
        let defaultErr = Toastr.methods.e;
        Toastr.methods.e = function(msg, title) {
            defaultErr.call(this, {
                msg: msg,
                title: title,
                timeout: 0
            });
        };

        Vue.use(Toastr, {
            defaultPosition: 'toast-top-center',
            defaultTimeout: 3000,
            defaultProgressBarValue: 0
        });

    }
}
