export const LOCALSTORAGE_TOKEN = 'app_token';
export const LOCALSTORAGE_USER = 'app_user';

export default class Auth {
    constructor(options) {
    }

    login(username, password) {
        return window.Vue.$http.post('/auth/login', {
            username: username,
            password: password
        }).then((response) => {
            this.setToken(response.data.access_token);
            this.setUser(response.data.user);
            return response;
        });
    }

    logout() {
        localStorage.removeItem(LOCALSTORAGE_USER);
        localStorage.removeItem(LOCALSTORAGE_TOKEN);
        return Promise.resolve();
    }

    setUser(user) {
        return localStorage.setItem(LOCALSTORAGE_USER, JSON.stringify(user));
    }

    user() {
        return JSON.parse(localStorage.getItem(LOCALSTORAGE_USER));
    }

    setToken(token) {
        return localStorage.setItem(LOCALSTORAGE_TOKEN, token);
    }

    token() {
        return localStorage.getItem(LOCALSTORAGE_TOKEN);
    }
}

export function token()
{
    return localStorage.getItem(LOCALSTORAGE_TOKEN);
}

export function user() {
    return JSON.parse(localStorage.getItem(LOCALSTORAGE_USER));
}
