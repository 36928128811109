import select2 from 'select2';
import jQuery from 'jquery';
let $ = jQuery;

export default {
    props: ['options', 'config', 'value'],
    template: '<select><slot></slot></select>',
    mounted() {
        var vm = this;
        $(this.$el)
        // init select2
            .select2({...{ data: this.options}, ...this.config})
            .val(this.value)
            .trigger('change')
            // emit event on change.
            .on('change', function () {
                vm.$emit('input', this.value)
            })
    },
    watch: {
        value(value) {
            // update value
            $(this.$el)
                .val(value)
                .trigger('change')
        },
        options(options) {
            // update options
            $(this.$el).empty().select2({...{ data: options}, ...this.config});
        }
    },
    destroyed() {
        $(this.$el).off().select2('destroy')
    }
};

