// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/es6/promise'
import 'core-js/es6/string'
import 'core-js/es7/array'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from './router';
import Toastr from './services/Toastr';
import Moment from 'moment';
import Api from './services/Api';
import Auth from './services/Auth';
import LoadingButton from '@/shared/LoadingButton';
import Select2 from '@/shared/select2'
import BootstrapMixin from './bootstrap/mixin';
import BootstrapState from './bootstrap/state';

Vue.component('select2', Select2);

Vue.prototype.$env = process.env;

Vue.use(BootstrapState);
Vue.use(BootstrapMixin);

Vue.prototype.$http = new Api({
    baseUrl: Vue.prototype.$env.VUE_APP_API_URL
});

Vue.prototype.$moment = Moment;
Moment.locale('nl');

Vue.prototype.$auth = new Auth();

Vue.use(BootstrapVue);

Vue.use(Toastr);
Vue.prototype.$toastr.defaultPreventDuplicates = true;

Vue.component('loading-button', LoadingButton);

Vue.mixin({
    methods: {
        /**
         * Only allows number input (digits and dot).
         * @param {object} e
         * @return {boolean}
         */
        onlyNumber: function(e) {
            e = e ? e : window.event;
            let charCode = (e.which) ? e.which : e.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 45) {
                e.preventDefault();
                if(charCode === 44) {
                    // Comma entered. Fill in a dot (.)
                    e.target.value = e.target.value + '.';
                }
            } else {
                return true;
            }
        }
    }
});

Vue.filter('toCurrency', function (value) {
    value = isNaN(value) ? 0 : parseFloat(value);
    let formatter = new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    });
    return formatter.format(value);
});

Vue.filter('minutesToTime', function (value) {
    value = isNaN(value) ? 0 : value;
    return String(Math.floor(value / 60)).padStart(2, '0') + ':' + String(value % 60).padStart(2, '0');
});

/* eslint-disable no-new */
window.Vue = new Vue({
    el: '#app',
    router,
    template: '<App/>',
    components: {
        App
    }
});
