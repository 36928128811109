export default {
    install(Vue) {
        let $config = {
            api_config: {},
            api_config_topdesk: {},
            api_config_teamleader: {},
        };

        Vue.prototype.$config = $config;
    }
}
