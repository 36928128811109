import axios from 'axios';
import get from 'lodash.get';

export default class Api {
    constructor(options) {
        return this.create(options);
    }

    create(options) {
        let instance = axios.create({
            baseURL: options.baseUrl,
            headers: {
                post: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            },
            responseType: 'json'
        });
        instance.interceptors.request.use((request) => {
            let token = window.Vue.$auth.token();
            if(token) {
                request.headers.Authorization = 'Bearer ' + token;
            }
            return request;
        });
        instance.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if(get(error, 'response.status') === 401) {
                window.Vue.$auth.logout();
                window.Vue.$router.push({name: 'login'});
                return Promise.reject(error);
            }
            let message = get(error, 'response.data.error.message') || error.message;
            window.Vue.$toastr.e(message);
            return Promise.reject(error);
        });

        return instance;
    }
}
